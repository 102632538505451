import React, { useState } from "react";
import Thoughts from "../thoughts.json";
import _ from "lodash";


export default function () {
    const thoughts = Thoughts.thoughts
    const num = _.size(thoughts)
    const [index, setIndex] = useState(Math.floor(Math.random() * num))

    return (
        // the number should be something to represent the highest index of the array
        <div onClick={() => setIndex(Math.floor(Math.random() * num))}>
            {thoughts[index].content}
        </div>
    );
}