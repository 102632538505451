import './App.css';
import RandomThought from './components/RandomThought';
import { useState } from "react";


function App() {
  const [isShowingThoughts, setIsShowingThoughts] = useState(false);
  const handleOnMouseIn = () => {
    setIsShowingThoughts(true);
  }
  const handleOnMouseOut = () => {
    setIsShowingThoughts(false);
  }
  return (
    <div className="App">
      <header className="App-header">
        <div onMouseEnter={handleOnMouseIn} onMouseOut={handleOnMouseOut}>
          { isShowingThoughts ? <RandomThought /> : <p> witpom </p> }
        </div>
      </header>
    </div>
  );
}

export default App;
